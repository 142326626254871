import React from "react";
import TrackChangesSVG from "./track-changes-svg";

type WalletBoxProps = {
  walletName: string;
};

const WalletBox = (props: WalletBoxProps) => {
  return (
    <div className="bg-gray-50 rounded-lg border px-3 py-2">
      <div className="text-xs text-gray-500 font-semibold mb-2">{props.walletName}</div>
      <div className="text-sm font-bold mb-2">
        {"0.00000000 "}
        <small className="text-gray-500 font-medium">BTC</small>
      </div>
      <div className="flex flex-row items-center text-green-600 gap-2">
        <div className="h-6 w-6">
          <TrackChangesSVG />
        </div>
        <div className="text-xs">0,00 (+0%)</div>
      </div>
    </div>
  );
};

export default WalletBox;

import React from "react";

const TrackChangesSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor">
      <path d="M37.8 9.5Q40.65 12.25 42.325 15.975Q44 19.7 44 24Q44 28.15 42.425 31.8Q40.85 35.45 38.15 38.15Q35.45 40.85 31.8 42.425Q28.15 44 24 44Q19.85 44 16.2 42.425Q12.55 40.85 9.85 38.15Q7.15 35.45 5.575 31.8Q4 28.15 4 24Q4 19.85 5.575 16.2Q7.15 12.55 9.85 9.85Q12.55 7.15 16.2 5.575Q19.85 4 24 4H25.5V20.3Q26.6 20.75 27.3 21.75Q28 22.75 28 24Q28 25.65 26.825 26.825Q25.65 28 24 28Q22.35 28 21.175 26.825Q20 25.65 20 24Q20 22.75 20.7 21.75Q21.4 20.75 22.5 20.3V15.1Q19.25 15.65 17.125 18.125Q15 20.6 15 24Q15 27.75 17.625 30.375Q20.25 33 24 33Q27.75 33 30.375 30.375Q33 27.75 33 24Q33 21.95 32.2 20.25Q31.4 18.55 30 17.3L32.15 15.15Q33.9 16.8 34.95 19.075Q36 21.35 36 24Q36 29 32.5 32.5Q29 36 24 36Q19 36 15.5 32.5Q12 29 12 24Q12 19.35 15 15.975Q18 12.6 22.5 12.05V7.05Q15.95 7.6 11.475 12.45Q7 17.3 7 24Q7 31.1 11.95 36.05Q16.9 41 24 41Q31.1 41 36.05 36.05Q41 31.1 41 24Q41 20.3 39.575 17.15Q38.15 14 35.65 11.65Z" />
    </svg>
  );
};

export default TrackChangesSVG;
